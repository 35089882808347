import React from "react"
import Skill from "./skill"
import { ProfileType } from "../../types"

const Skills = ({ skills, skill_percentages }) => (
  <>
    <h5 className="font-header font-semibold text-front text-sm uppercase mb-6">
      Programming languages
    </h5>
    <ul className="flex flex-wrap grid grid-cols-2 gap-2 md:gap-4">
      {skills.map((skill, i) => (
        <Skill skill={skill} key={skill} skill_percentage={skill_percentages[i]} />
      ))}
    </ul>
  </>
);

Skills.propTypes = {
  skills: ProfileType.skills,
  skill_percentages: ProfileType.skill_percentages,
};

export default Skills
