import React from "react"
import { ProfileType } from "../../types"

const Footer = ({ name }) => (
  <footer className="bg-back mt-16 pt-8 pb-16">
    <div className="md:max-w-screen-sm lg:max-w-screen-xl mx-auto px-4 flex items-center">
      <div className="w-2/3 text-back-dark font-header text-xs">
        <b>
          &copy; {new Date().getFullYear()} {name}
        </b>{" "}
        Made with <a href="https://gatsbyjs.com" target="_blank" rel="noreferrer">Gatsby</a> & <a href="https://tailwindcss.com/" target="_blank" rel="noreferrer">Tailwindcss</a>. Hosted on AWS S3 with CloudFront.
        {/*All rights reserved.*/}
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  name: ProfileType.name,
};

export default Footer
