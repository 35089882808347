import { string } from "prop-types"
import React from "react"

const Skill = ({ skill, skill_percentage }) => (
  <div className={`relative rounded-full border-skill-${2} border-black border-${skill_percentage}`}>
    <li
      className={`flex justify-center items-center rounded-full border border-black border-skill-${2}`}
    >
      <span className="absolute font-header font-semibold text-front text-sm md:text-base px-2 text-center">
        {skill}
      </span>
      <svg height="100" width="100"/>
    </li>
  </div>
);

Skill.propTypes = {
  skill: string.isRequired,
  skill_percentage: string.isRequired,
};

export default Skill
