import { graphql } from "gatsby"
import { arrayOf, string } from "prop-types"

export const InterestType = {
  name: string.isRequired,
  urls: arrayOf(string).isRequired,
  titles: arrayOf(string).isRequired,
  icons: arrayOf(string).isRequired,
};

export const query = graphql`
  fragment InterestFragment on InterestsYaml {
    name
    urls
    titles
    icons
  }
`;
