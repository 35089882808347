import React from "react"
import { FaCompass, FaGithub } from "react-icons/fa"
import { ProjectType } from "../../types"

const ProjectIcon = ({ icon, url }) => (
  <span className="absolute right-0 bottom-0 mb-5 mr-5 text-back">
    {icon === "github" ? (
      <FaGithub className="w-6 h-6" />
    ) : (
      <div>
        <a
          aria-label="website"
          className="inline-block text-front opacity-50 hover:opacity-75 h-4 w-4 transition-opacity duration-150"
          href={url}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FaCompass className="w-6 h-6" />
        </a>
      </div>
    )}
  </span>
);

ProjectIcon.propTypes = {
  icon: ProjectType.icon,
  url: ProjectType.url
};

export default ProjectIcon
