import { graphql } from "gatsby"
import { arrayOf, bool, number, object, shape, string } from "prop-types"

export const ProfileType = {
  about: string.isRequired,
  company: string.isRequired,
  email: string.isRequired,
  focus: string.isRequired,
  focus_url: string,
  focus_url_3: string,
  company_url: string,
  for_hire: bool.isRequired,
  image: shape({
    childImageSharp: object.isRequired,
    publicURL: string.isRequired,
  }),
  initials: string.isRequired,
  location: string.isRequired,
  name: string.isRequired,
  profession: string.isRequired,
  relocation: bool.isRequired,
  skills: arrayOf(string).isRequired,
  skill_percentages: arrayOf(number).isRequired,
  tools: arrayOf(string).isRequired,
  cloud: arrayOf(string).isRequired,
};

export const query = graphql`
  fragment ProfileFragment on ProfileYaml {
    about
    company
    email
    focus
    focus_url
    focus_url_3
    company_url
    for_hire
    image {
      childImageSharp {
        fixed(width: 144, height: 144, quality: 85) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
      publicURL
    }
    initials
    location
    name
    profession
    relocation
    skills
    skill_percentages
    tools
    cloud
  }
`;
