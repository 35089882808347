import React from "react"
import { ProjectType } from "../../types"
import SocialIcon from '../social-links/social-icon';

const Interest = props => {
  const { name, urls, titles, icons } = props;
  return (
    <div className="relative flex flex-wrap p-4 lg:p-8 bg-no-repeat text-sm">
      <div className="lg:flex-1">
        <h4 className="font-bold">{name}</h4>
        {urls.map((url, idx) =>
          <>
            <ul className="pr-2 flex my-2">
              <a
                aria-label={name}
                className={`inline-flex w-10 h-10 mr-4 justify-center items-center rounded-full -ml-3 bg-back-light text-front hover:shadow-lg transition-shadow duration-150`}
                href={url}
                key={name}
                rel="noopener noreferrer"
                target="_blank"
              >
                <SocialIcon name={icons[idx]} className="w-6 h-6"/>
              </a>
              <a
                className="text-front underline break-all hover:opacity-75 transition-opacity duration-150 inline-flex justify-center items-center"
                href={url}
                rel="noreferrer noopener"
                target="_blank"
              >
                {titles[idx]}
              </a>
            </ul>
          </>)
        }
      </div>
    </div>
  )
};

Interest.propTypes = ProjectType;

export default Interest;
