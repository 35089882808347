import React from "react"
import { ProfileType } from "../../types"
import resume from '../../../content/bouweceunen.resume.pdf';

const Resume = () => (
  <>
    <div className="inline-flex items-center bg-front mt-6 w-auto">
      <span className="text-back font-header font-bold py-1 px-3 text-lg">
        <a href={resume} target='_blank' rel="noreferrer">RESUME</a>
      </span>
    </div>
  </>
);

Resume.propTypes = {
  resume: ProfileType.resume,
};

export default Resume
