import React from "react"
import { arrayOf, InterestType, shape } from "../../types"
import Interest from './interest';

const Interests = ({ interests }) => (
  <>
    <h5 className="font-header font-semibold text-front text-sm uppercase mb-3 mt-3">
      Interests
    </h5>
    {interests.map((interest, i) => (
      <Interest key={`${interest.name}_${i}`} {...interest} />
    ))}
  </>
);

Interests.propTypes = {
  interests: arrayOf(shape(InterestType)),
};

export default Interests
