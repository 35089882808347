import React from "react"
import { ProfileType } from "../../types"

const Email = ({ email }) => (
  <>
    <div className="inline-flex items-center bg-front mt-6 w-auto">
      <span className="text-back font-header font-bold py-1 px-3 text-lg">
        {email}
      </span>
    </div>
  </>
);

Email.propTypes = {
  email: ProfileType.email,
};

export default Email
